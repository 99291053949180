import {mapGetters, mapMutations} from "vuex";

//sections

export default {
  name: "certificate",
  components: {},
  data() {
    return {
      index: null,
      rotate: 0,
      scale: 1.25,
      isChildImages: false,
    }
  },
  created() {
  },
  mounted() {
    document.title = `${this.currentProduct.title +' - '+ this.$t('productCard.certificates.title')}  | karbosnab`
  },
  computed: {
    ...mapGetters({
      currentProduct: 'catalog/currentProduct'
    })
  },

  methods: {
    largeImage(index,item) {
      if(!item.pdfFile){
        this.isChildImages = true
        this.index = index
      }else{
        window.open(item.pdfFile, '_blank');
      }

    },

    largeChildImage(index) {
      let large = this.$refs.largeImageWrapper
      large.style.backgroundImage = `url("${this.currentProduct.certificates.data[this.index].images.data[index].path}")`
      large.style.transform = `scale(${this.scale})`
    },

    closeImage() {
      this.isChildImages = false
    },

    zoomUpElement() {
      this.scale === 2.25 ? this.scale = 1.25 : this.scale += .25
      this.$refs.largeImageWrapper.style.transform = `scale(${this.scale})`
    }
    ,
    zoomOutElement() {
      this.scale <= 2.25 && this.scale >= 1.25 ? this.scale -= .25 : this.scale = 1
      this.$refs.largeImageWrapper.style.transform = `scale(${this.scale})`
    },
    ...
      mapMutations({})
  }
}
